// https://github.com/remix-run/remix/issues/2947

import { RemixBrowser } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import posthog from 'posthog-js';
import { StrictMode, startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';

interface EnvVariables {
  SENTRY_DSN?: string;
  SENTRY_ENVIRONMENT?: string;
  POSTHOG_API_KEY?: string;
}

declare global {
  interface Window {
    ENV: EnvVariables;
  }
}

if (window.ENV?.SENTRY_DSN)
  Sentry.init({
    dsn: window.ENV.SENTRY_DSN,
    environment: window.ENV.SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.01,
    integrations: [Sentry.browserTracingIntegration({}), Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0, // don't record normal sessions (we use Clarity)
    replaysOnErrorSampleRate: 1.0, // record all errors
  });

function hydrate() {
  if (window.ENV?.POSTHOG_API_KEY) {
    posthog.init(window.ENV.POSTHOG_API_KEY, {
      api_host: 'https://app.posthog.com',
    });
  }

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
